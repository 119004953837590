<template>
  <header class="masthead">
    <div class="overlay"></div>
    <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
      <source :src="GetVideo" type="video/mp4" />
    </video>
    <div class="container h-100">
      <div class="d-flex h-100 text-center align-items-center">
        <div class="w-100 text-white" id="head">
          <h1 class="display-3 user-select-none" v-t="'Welcome To CHAMT!'"></h1>
          <p class="lead mb-0 user-select-none text-left" v-t="'COMPANY1'"></p>
          <br />
          <p class="lead mb-0 user-select-none text-left" v-t="'COMPANY2'"></p>
          <br />
          <p class="lead mb-0 user-select-none text-left" v-t="'COMPANY3'"></p>
          <br />
          <a class="btn btn-primary btn-xl text-uppercase user-select-none js-scroll-trigger" href="#products" v-t="'TELL ME MORE'"></a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: "Masthead",
  computed: {
    GetVideo() {      
      return "video" + (Math.floor(Math.random()*2) + 1) + ".mp4"
    },
  },
};
</script>