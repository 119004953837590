import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
}

function defalutlocale() {
  var locale = (navigator.language || navigator.browserLanguage).toLowerCase();
  if (locale.includes("zh-hk")) locale = "zh-tw";
  if (locale.includes("hant")) locale = "zh-tw";
  if (locale.includes("hans")) locale = "zh-cn";
  if (!locale.includes("zh-cn") && !locale.includes("zh-tw")) locale = "us-en"
  return locale;
}

export default new VueI18n({
  locale: defalutlocale(),
  fallbackLocale: "us-en",
  messages: loadLocaleMessages(),
});
