<template>
  <span id="app">
    <Navigation />
    <Masthead />
    <Products />
    <ProductsGrid />
    <Contact />
    <Footer />
    <ProductsModal
      v-for="product in Products"
      v-bind:product="product"
      v-bind:key="product.Name"
    ></ProductsModal>
  </span>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import Masthead from "./components/Masthead.vue";
import Products from "./components/Products.vue";
import ProductsGrid from "./components/ProductsGrid.vue";
import Contact from "./components/Contact.vue";
import Footer from "./components/Footer.vue";
import ProductsModal from "./components/ProductsModal.vue";

export default {
  name: "App",
  components: {
    Navigation,
    Masthead,
    Products,
    ProductsGrid,
    Contact,
    Footer,
    ProductsModal,
  },
  mounted: function() {        
    this.$ga.page('/');
  },
  computed: {
    Products() {
      return this.$i18n.messages[
        this.$i18n.locale
      ].GrindingPolishingProducts.concat(
        this.$i18n.messages[
          this.$i18n.locale
      ].FilterProducts).concat(
        this.$i18n.messages[
          this.$i18n.locale
      ].BrushSpongeProducts);
    },
  },
};
</script>
