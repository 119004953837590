<template>
  <div class="portfolio-modal modal fade" :id="'ProductModal' + product.Name" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="close-modal" data-dismiss="modal">
          <img src="close-icon.svg" alt="Close modal" />
        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <div class="modal-body">
                <h2 class="text-uppercase">{{ product.Title }}</h2>
                <p class="item-intro text-muted">{{ product.SubTitle }}</p>
                <img class="img-fluid d-block mx-auto" v-lazy="'products/' + product.Name + '.jpg'" :alt="product.Title" />
                <p class="text-left">{{ product.Description }}</p>
                <ul class="list-inline">
                  <li></li>
                </ul>
                <button class="btn btn-primary" data-dismiss="modal" type="button">
                  <i class="fas fa-times mr-1"></i>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductsModal",
  props: ["product"],
};
</script>
