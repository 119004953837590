<template>
  <div class="col-lg-4 col-sm-6 mb-4">
    <div class="portfolio-item">
      <a class="portfolio-link" data-toggle="modal" :href="'#ProductModal' + product.Name">
        <div class="portfolio-hover">
          <div class="portfolio-hover-content">
            <i class="fas fa-plus fa-3x"></i>
          </div>
        </div>
        <img class="img-fluid" v-lazy="'products/' + product.Name + '.jpg'" :alt="product.Title" />
      </a>
      <div class="portfolio-caption">
        <div class="portfolio-caption-heading">{{ product.Title }}</div>
        <div class="portfolio-caption-subheading text-muted">
          {{ product.SubTitle }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductsGridCell",
  props: ["product"],
};
</script>
