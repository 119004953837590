<template>
  <section class="page-section bg-light" id="portfolio">
    <div id="GrindingPolishing"></div>
    <hr />
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase user-select-none" v-t="'GRINDING & POLISHING'"></h2>
        <h3 class="section-subheading text-muted">
        </h3>
      </div>
      <div class="row">
        <ProductsGridCell v-for="product in GrindingPolishingProducts" v-bind:product="product" v-bind:key="product.Name"></ProductsGridCell>
      </div>
    </div>
    <div id="Filter"></div>
    <hr />
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase user-select-none" v-t="'FILTER'"></h2>
        <h3 class="section-subheading text-muted">
        </h3>
      </div>
      <div class="row">
        <ProductsGridCell v-for="product in FilterProducts" v-bind:product="product" v-bind:key="product.Name"></ProductsGridCell>
      </div>
    </div>
    <div id="BrushSponge"></div>
    <hr />
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase user-select-none" v-t="'BRUSH & SPONGE'"></h2>
        <h3 class="section-subheading text-muted">
        </h3>
      </div>
      <div class="row">
        <ProductsGridCell v-for="product in BrushSpongeProducts" v-bind:product="product" v-bind:key="product.Name"></ProductsGridCell>
      </div>
    </div>
  </section>
</template>

<script>
import ProductsGridCell from "./ProductsGridCell.vue";

export default {
  name: "ProductsGrid",
  components: {
    ProductsGridCell,
  },
  computed: {
    GrindingPolishingProducts() {      
      return this.$i18n.messages[this.$i18n.locale].GrindingPolishingProducts;
    },
    FilterProducts() {
      return this.$i18n.messages[this.$i18n.locale].FilterProducts;
    },
    BrushSpongeProducts() {
      return this.$i18n.messages[this.$i18n.locale].BrushSpongeProducts;
    },
  },
};
</script>
