<template>
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
    <div class="container">
      <a class="navbar-brand js-scroll-trigger" href="#page-top" @click="HideNavbar">
        <img :src="'navbar-logo.' + LogoType" :alt="$t('Welcome To CHAMT!')" />
      </a>
      <button
        class="navbar-toggler navbar-toggler-right"
        type="button"
        data-toggle="collapse"
        data-target="#navbarResponsive"
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span v-t="'MENU!'"></span>
        <i class="fas fa-bars ml-1"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav text-uppercase ml-auto">
          <li class="nav-item">
            <a class="nav-link user-select-none js-scroll-trigger" href="#products" @click="HideNavbar" v-t="'PRODUCTS'"> </a>
          </li>
          <li class="nav-item">
            <a class="nav-link user-select-none js-scroll-trigger" href="#GrindingPolishing" @click="HideNavbar" v-t="'GRINDING & POLISHING'"> </a>
          </li>
          <li class="nav-item">
            <a class="nav-link user-select-none js-scroll-trigger" href="#Filter" @click="HideNavbar" v-t="'FILTER'"> </a>
          </li>
          <li class="nav-item">
            <a class="nav-link user-select-none js-scroll-trigger" href="#BrushSponge" @click="HideNavbar" v-t="'BRUSH & SPONGE'"> </a>
          </li>
          <li class="nav-item">
            <a class="nav-link user-select-none js-scroll-trigger" href="#team" @click="HideNavbar" v-t="'CONTACT'"> </a>
          </li>
          <li class="nav-item">
            <a class="btn btn-dark btn-social mx-2" href="https://www.facebook.com/chamt.taiwan/" target="_blank" rel="noopener" title="Facebook"><i class="fab fa-facebook-f fa-2x"></i></a>
          </li>
          <li class="nav-item">
            <a class="btn btn-dark btn-social mx-2" href="http://line.me/ti/p/%40sqp4200b" target="_blank" rel="noopener" title="Line"><i class="fab fa-line fa-2x"></i></a>
          </li>
          <li class="nav-item">
            <a class="btn btn-dark btn-social mx-2" href="http://webmail.chamt.com.tw/" target="_blank" title="Email"><i class="fas fa-envelope-square fa-2x"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import $ from "jQuery";

export default {
  name: "Navigation",
  data() {
    return {
      LogoType: navigator.userAgent.indexOf("Trident") > 0 || navigator.userAgent.indexOf("Edge") > 0 ? "png" : "svg",
    };
  },
  methods: {
    HideNavbar() {
      $("#navbarResponsive").collapse("hide");
    },
  },
};
</script>
