<template>
  <section class="page-section" id="products">
    <div class="container">
      <div class="row text-center">
        <a class="col-md-4 nav-link js-scroll-trigger" href="#GrindingPolishing" @click="HideNavbar">
          <span class="fa-stack fa-4x">
            <i class="fas fa-circle fa-stack-2x text-primary"></i>
            <i class="fas fa-ring fa-stack-1x fa-inverse"></i>
          </span>
          <h4 class="my-3 user-select-none" v-t="'GRINDING & POLISHING'"></h4>
          <p class="text-muted text-left user-select-none" v-html="$t('PRODUCTS1')"></p>
        </a>
        <a class="col-md-4 nav-link js-scroll-trigger" href="#Filter" @click="HideNavbar">
          <span class="fa-stack fa-4x">
            <i class="fas fa-circle fa-stack-2x text-primary"></i>
            <i class="fas fa-head-side-mask fa-stack-1x fa-inverse"></i>
          </span>
          <h4 class="my-3 user-select-none" v-t="'FILTER'"></h4>
          <p class="text-muted text-left user-select-none" v-html="$t('PRODUCTS2')"></p>
        </a>
        <a class="col-md-4 nav-link js-scroll-trigger" href="#BrushSponge" @click="HideNavbar">
          <span class="fa-stack fa-4x">
            <i class="fas fa-circle fa-stack-2x text-primary"></i>
            <i class="fas fa-bolt fa-stack-1x fa-inverse"></i>
          </span>
          <h4 class="my-3 user-select-none" v-t="'BRUSH & SPONGE'"></h4>
          <p class="text-muted text-left user-select-none" v-html="$t('PRODUCTS3')"></p>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import $ from "jQuery";

export default {
  name: "Navigation",
  methods: {
    HideNavbar() {
      $("#navbarResponsive").collapse("hide");
    },
  },
};
</script>
