<template>
  <section class="page-section bg-light" id="team">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase" v-t="'CONTACT'"></h2>
        <h3 class="section-subheading text-muted"></h3>
      </div>
      <div class="row" v-lazy-container="{ selector: 'img', loading: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' }">
        <div class="col-lg-3">
          <div class="team-member">
            <img class="mx-auto rounded-circle" :data-src="Taipei" :alt="$t('Taipei')" />
            <h4 v-t="'Taipei'"></h4>
            <p class="text-muted text-left">
              <span v-t="'Address'"></span><br />
              7F-3, No.171, Sec.2, Chang-An E. Rd., Taipei Taiwan ROC<br />
              TEL: +886(2) 2711-4145<br />
              FAX: +886(2) 2731-4946<br />
              E-mail:
              <a href="#mailto" data-toggle="modal" @click="mailto('Taipei')">hkoshin@chamt.com.tw</a>
            </p>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="team-member">
            <img class="mx-auto rounded-circle" :data-src="Malaysia" :alt="$t('Malaysia')" />
            <h4 v-t="'Malaysia'"></h4>
            <p class="text-muted text-left">
              TEL: +603-32903077~8<br />
              FAX: +603-32903048<br />
              E-mail:
              <a href="#mailto" data-toggle="modal" @click="mailto('Malaysia')">chamths123@gmail.com</a>
            </p>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="team-member">
            <img class="mx-auto rounded-circle" :data-src="DongGuan" :alt="$t('China (Dong-Guan)')" />
            <h4 v-t="'China (Dong-Guan)'"></h4>
            <p class="text-muted text-left">
              TEL: +86(769)8584-0588<br />
              FAX: +86(769)8538-1469<br />
              E-mail:
              <a href="#mailto" data-toggle="modal" @click="mailto('China (Dong-Guan)')">leedia_gd@chamt.com.tw</a>
            </p>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="team-member">
            <img class="mx-auto rounded-circle" :data-src="KunShan" :alt="$t('China (Kun-Shan)')" />
            <h4 v-t="'China (Kun-Shan)'"></h4>
            <p class="text-muted text-left">
              TEL: +86(512)5779-8283<br />
              FAX: +86(512)5776-2109<br />
              E-mail:
              <a href="#mailto" data-toggle="modal" @click="mailto('China (Kun-Shan)')">leedia_sh@chamt.com.tw</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="portfolio-modal modal fade" id="mailto" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="close-modal" data-dismiss="modal">
            <img src="close-icon.svg" alt="Close modal" />
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="modal-body">
                  <form @submit="doSubmit">
                    <div class="card border-dark rounded-0">
                      <div class="card-header p-0">
                        <div class="bg-dark text-white text-center py-2">
                          <h3><i class="fa fa-envelope"></i> {{ $t("CONTACT") }}</h3>
                          <p class="m-0">{{ MailTo }}</p>
                        </div>
                      </div>
                      <div class="card-body p-3">
                        <div class="form-group">
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text"><i class="fa fa-user text-primary"></i></div>
                            </div>
                            <input type="text" class="form-control" name="nombre" placeholder="您的姓名" required v-model="Name" />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text"><i class="fa fa-envelope text-primary"></i></div>
                            </div>
                            <input type="email" class="form-control" name="email" placeholder="EMail" required v-model="EMail" />
                          </div>
                        </div>

                        <div class="form-group">
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text"><i class="fa fa-comment text-primary"></i></div>
                            </div>
                            <textarea class="form-control vh-30" required v-model="Message"></textarea>
                          </div>
                        </div>
                        <button class="btn btn-dark btn-block rounded-0 py-2" type="submit" v-t="'Submit'"></button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      Taipei: require("../assets/pexels-belle-co-981153.jpg"),
      Malaysia: require("../assets/pexels-pixabay-433942.jpg"),
      DongGuan: require("../assets/pexels-berk-ozdemir-3779816.jpg"),
      KunShan: require("../assets/pexels-francesco-ungaro-1525041.jpg"),
      MailTo: "",
      Name: "",
      EMail: "",
      Message: "",
      ToEmail: "hkoshin@chamt.com.tw",
    };
  },
  methods: {
    mailto(Destination) {
      this.MailTo = this.$t(Destination);
      switch (Destination) {
        case "Taipei":
          this.ToEmail = "hkoshin@chamt.com.tw"
          break;        
        case "Malaysia":
          this.ToEmail = "chamths123@gmail.com"
          break;        
        case "China (Dong-Guan)":
          this.ToEmail = "leedia_gd@chamt.com.tw"
          break;        
        case "China (Kun-Shan)":
          this.ToEmail = "leedia_sh@chamt.com.tw"
          break;        
      }
    },
    doSubmit(e) {
      e.preventDefault();
      let loader = this.$loading.show();
      this.$axios
        .post("chamtmail.php", {
          ToEmail: this.ToEmail,
          Name: this.Name,
          EMail: this.EMail,
          Message: this.Message,
        })
        .then(() => {
          loader.hide();
          this.$("#mailto").modal("hide");
          this.$toasted.show(this.$t("Email Sent!"), { position: "top-center", duration: 1000 });
        });
    },
  },
};
</script>
