<template>
  <footer class="footer py-4">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4 text-lg-left">
          Copyright © CHAMT & CO., LTD. 2020
        </div>
        <div class="col-lg-4 my-3 my-lg-0">
          <a class="btn btn-dark btn-social mx-2" href="http://wpa.qq.com/msgrd?v=3&uin=2590660840&site=qq&menu=yes" target="_blank" rel="noopener" title="QQ"><i class="fab fa-qq fa-2x"></i></a>
          <a class="btn btn-dark btn-social mx-2" href="https://www.facebook.com/chamt.taiwan/" target="_blank" rel="noopener" title="Facebook"><i class="fab fa-facebook-f fa-2x"></i></a>
          <a class="btn btn-dark btn-social mx-2" href="http://line.me/ti/p/%40sqp4200b" target="_blank" rel="noopener" title="Line"><i class="fab fa-line fa-2x"></i></a>
        </div>
        <div class="col-lg-4 text-lg-right">
          <a class="mr-3 user-select-none js-scroll-trigger" href="#page-top" @click="SwitchLang('us-en')">
            English
          </a>
          <a class="mr-3 user-select-none js-scroll-trigger" href="#page-top" @click="SwitchLang('zh-tw')">
            繁體中文
          </a>
          <a class="mr-3 user-select-none js-scroll-trigger" href="#page-top" @click="SwitchLang('zh-cn')">
            简体中文
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  methods: {
    SwitchLang(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>
