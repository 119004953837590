import Vue from "vue";
import VueAnalytics from 'vue-analytics'
import App from "./App.vue";
import i18n from "./i18n";
import VueLazyload from 'vue-lazyload';
import Loading from 'vue-loading-overlay';
import Toasted from 'vue-toasted';
import $ from "jQuery";
import axios from "axios";
import "./assets/styles.css";
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(VueAnalytics, {
  id: 'UA-288878-3',  
})

Vue.use(VueLazyload, {
  loading: 'loading.png',
})

Vue.use(Loading);
Vue.use(Toasted)

Vue.config.productionTip = false;
Vue.prototype.i18n = i18n;
Vue.prototype.$ = $;
Vue.prototype.$axios = axios;

new Vue({
  i18n,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
}).$mount("#app");

$('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function () {
  if (
      location.pathname.replace(/^\//, "") ==
          this.pathname.replace(/^\//, "") &&
      location.hostname == this.hostname
  ) {
      var target = $(this.hash);
      target = target.length
          ? target
          : $("[name=" + this.hash.slice(1) + "]");
      if (target.length) {
          $("html, body").animate(
              {
                  scrollTop: target.offset().top - 80,
              },
              1000,
              "easeInOutExpo"
          );
          return false;
      }
  }
});

$("body").scrollspy({
  target: "#mainNav",
  offset: 150,
});

var navbarCollapse = function() {
  if ($("#mainNav").offset().top > 50) {
    $("#mainNav").addClass("navbar-shrink");
  } else {
    $("#mainNav").removeClass("navbar-shrink");
  }
};
navbarCollapse();
$(window).scroll(navbarCollapse);